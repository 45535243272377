import { useMemo, useState } from "react";
import Lottie from "react-lottie-player";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { breakpoints, Grid, H2, H5, Spacing } from "@boxt/design-system";

import createKey from "@Helpers/createKey";
import { Products } from "@Collections/products";
import { actions, category, labels, ReactGAEvent } from "@Lib/googleAnalytics";
import Image from "@Components/Image";
import { FinishQuote, Postcode } from "@Components/screener";
import useMedia from "@Hooks/useMedia";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import { getDefaultUi } from "./helpers";
import lottieJson from "./scroll-down.json";
import {
  Arrow,
  BenefitWrapper,
  Container,
  ContentContainer,
  CustomIconContainer,
  DesktopArrow,
  HeaderIconContainer,
  HeroImage,
  HeroImageTopPositioned,
  LowerTextWrapper,
  PostcodeContainer,
  Subheading,
  Tick,
  UpperTextWrapper,
  VanWrapper,
} from "./styles";
import type { Breakpoints, Content, Props, TextContent } from "./types";

const getScreenSize = (isLg: boolean, isMd: boolean): "sm" | "md" | "lg" => {
  if (!isLg && isMd) return "md";
  if (isLg && isMd) return "lg";
  return "sm";
};

const handleAnalyticEvent = (action: string) => {
  ReactGAEvent({
    action,
    category: category.landing,
    label: labels.hero,
  });
};

const LandingHero = ({
  i18nNamespace,
  onSubmit,
  content,
  variant,
  imageFolder,
  arrowHref,
  screeningId,
  productType,
  onFinishQuote,
  hasAnalyticEventOnSubmit = true,
  initialPostcode,
  onStartQuote,
}: Props) => {
  const isPostcodeAtTheEndOfaScreener = [ScreenerVariant.BoilerInstallationExperiment].includes(
    variant as ScreenerVariant,
  );
  const hasExistingScreening = Boolean(screeningId);

  const [hasPostCodeField, setHasPostCodeField] = useState(
    isPostcodeAtTheEndOfaScreener ? false : !hasExistingScreening,
  );

  const { t } = useTranslation(i18nNamespace);
  const { t: tCommon } = useTranslation("common");
  const isLg = useMedia(`(min-width: ${breakpoints.lg.width})`);
  const isMd = useMedia(`(min-width: ${breakpoints.md.width}) and (min-height: 25rem)`);

  const handleOnSubmit = async (values: { postcode: string }) => {
    if (hasAnalyticEventOnSubmit) {
      handleAnalyticEvent(actions.postcodeGetStarted);
      ReactGAEvent({ action: actions.postcode, category: category.screener, label: values.postcode });
    }
    await onSubmit(values);
  };

  const innerContent = useMemo((): Content => {
    const defaultText: TextContent = {
      benefits: t("hero.benefits", {}, { returnObjects: true }),
      heading: t("hero.heading"),
      subheading: t("hero.subheading"),
    };

    const defaultContent: Content = { ...defaultText, ...getDefaultUi(imageFolder, productType) };

    return Object.keys(defaultContent).reduce((acc, curr) => {
      if (!isEmpty(content) && content[curr]) {
        // check if string value which coming from CMS is not empty
        if (typeof content[curr] === "string") {
          !isEmpty(content[curr]) && (acc[curr] = content[curr]);
        } else {
          acc[curr] = content[curr];
        }
      }
      return acc;
    }, defaultContent);
  }, [content, imageFolder, productType, t]);

  const backgroundUrl: string = useMemo(() => {
    const urlsMap: Record<Breakpoints, string> = {
      lg: innerContent.background_image_desktop,
      md: innerContent.background_image_tablet,
      sm: innerContent.background_image_mobile,
    };

    return urlsMap[getScreenSize(isLg, isMd)];
  }, [isLg, isMd, innerContent]);

  const BackgroundHeroImage = useMemo(() => {
    let imageComponent;

    if (productType === Products.HomeCover) {
      imageComponent = HeroImageTopPositioned;
    } else if (
      productType === Products.BoilerRental ||
      (productType === Products.Repair && !arrowHref.includes("google"))
    ) {
      imageComponent = HeroImage;
    } else {
      imageComponent = Image;
    }

    return imageComponent;
  }, [productType, arrowHref]);

  const hasImage =
    innerContent.van_image && ![Products.Solar, Products.Boiler, Products.ProductRecall].includes(productType);

  return (
    <Container id={content.id}>
      <Grid.Row>
        <BackgroundHeroImage
          alt={innerContent.background_image_alt}
          layout="fill"
          objectFit="cover"
          quality={innerContent.background_image_quality}
          src={backgroundUrl}
        />
      </Grid.Row>
      <Grid.Row>
        <Grid.Col
          $blur={innerContent.background_blur}
          $color={innerContent.background_color}
          $opacity={parseFloat(innerContent.background_opacity)}
          $productType={productType}
          as={ContentContainer}
          id="landing_hero_main_ctas"
        >
          <Spacing mt={!hasImage ? 0 : 6} />
          {hasImage && (
            <VanWrapper $productType={productType}>
              <Image
                alt={innerContent.van_image_alt}
                height={productType === Products.BoilerRental ? 60 : 135}
                quality={innerContent.van_image_quality}
                src={innerContent.van_image as string}
                width={productType === Products.BoilerRental ? 149 : 350}
              />
            </VanWrapper>
          )}

          <UpperTextWrapper>
            {content.header_image && (
              <Spacing mb={{ md: 2, sm: 1 }} mt={{ lg: 1, md: 2, sm: 0 }}>
                <HeaderIconContainer>
                  <Image alt={tCommon("alt-tags.bullet-point")} height={64} src={content.header_image} width={64} />
                </HeaderIconContainer>
              </Spacing>
            )}
            <H2
              align={{ lg: "left", md: "center" }}
              bottom={{ lg: 3, md: 2, sm: 4 }}
              boxtTheme={innerContent.heading_theme}
              top={{ lg: 4, md: 2, sm: 0 }}
            >
              {innerContent.heading}
            </H2>
            <Subheading
              $boldTheme={innerContent.heading_theme_strong}
              align={{ lg: "left", md: "center" }}
              bottom={{ lg: 0, md: 3, sm: 0 }}
              boxtTheme={innerContent.heading_theme}
              size="large"
            >
              {innerContent.subheading}
            </Subheading>
          </UpperTextWrapper>
          {hasPostCodeField ? (
            <PostcodeContainer>
              <Postcode
                buttonColor={innerContent.cta_color}
                initialPostcode={initialPostcode}
                label={t("postcode.label")}
                onFocus={() => handleAnalyticEvent(actions.postcodeFocus)}
                onSubmit={handleOnSubmit}
                placeholder={{
                  md: t("postcode.placeholder.md"),
                  sm: t("postcode.placeholder.sm"),
                }}
                productType={productType}
                submitText={t("postcode.submit")}
                tooltipBgColor={innerContent.error_bubble_bg_color}
                tooltipTextTheme={innerContent.error_bubble_text_theme}
              />
            </PostcodeContainer>
          ) : (
            <FinishQuote
              hasExistingScreening={hasExistingScreening}
              isPostcodeAtTheEndOfaScreener={isPostcodeAtTheEndOfaScreener}
              onFinishQuote={onFinishQuote}
              onStartQuote={() => {
                if (!isPostcodeAtTheEndOfaScreener) return setHasPostCodeField(true);
                onStartQuote?.();
              }}
            />
          )}
          <LowerTextWrapper $color={innerContent.benefits_color}>
            {innerContent.benefits.map(({ benefit }) => (
              <BenefitWrapper key={createKey(benefit)}>
                {content.benefits_icon ? (
                  <Spacing as={CustomIconContainer} mr={2}>
                    <Image alt={tCommon("alt-tags.bullet-point")} height={20} src={content.benefits_icon} width={20} />
                  </Spacing>
                ) : (
                  <Tick stroke={innerContent.benefits_tick_color} />
                )}

                <H5 align={{ lg: "left", md: "center" }}>{benefit}</H5>
              </BenefitWrapper>
            ))}
          </LowerTextWrapper>
          <Arrow href={arrowHref}>
            <Lottie animationData={lottieJson} loop play style={{ height: 40, width: 40 }} />
          </Arrow>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={16}>
          <DesktopArrow href={arrowHref}>
            <Lottie animationData={lottieJson} loop play style={{ height: 40, width: 40 }} />
          </DesktopArrow>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
};

export default LandingHero;
